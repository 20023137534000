import config from "./config"

const getLink = (target: string) => {
  const data = {
    home: "/",
    diag: "/diagnostic/",
    formule: "/formules/",
    materiel: "/materiels/",
    faq: "/faq/",
    cgu: "/cgu/",
    resultat: "/acceptation/",
    souscription: "/souscription/",
    conseils: "/conseils/",
    quiSommesNous: "/qui-sommes-nous/",
    reseau: "/notre-reseau/",
    espaceClient: "https://maprotectionmaison.fr/",
  }
  return data[target]
}

export default { getLink }
